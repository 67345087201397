<script>
export default {
    food_drinks: "cibo & bevande",
    shopping: "shopping",
    home: "casa",
    transport: "trasporti",
    vehicles: "veicoli",
    entertainment: "instrattenimento",
    communication_pc: "comunicazione pc",
    financial_expenses: "spese finanziarie",
    income: "introiti",
    other: "altro",
    bars_venues: "bar & locali",
    restaurants_fast_food: "ristoranti & fast-food",
    groceries: "spesa",
    clothing_shoes: "abbigliamento & scarpe",
    pets: "animali domestici",
    beauty_wellness: "bellezza & benessere",
    stationery_tools: "cancelleria & utensili",
    home_garden: "casa & giardino",
    electronics_accessories: "elettronica, accessori",
    pharmacy: "farmacia",
    kids: "figli",
    jewelry_accessories: "gioielli & accessori",
    gift_expenses: "spese per regali",
    family_help: "aiuto familiare",
    leisure: "tempo libero",
    rent: "affitto",
    property_insurance: "assicurazione immobili",
    condominium: "condominio",
    energy_utilities: "energia & utenze",
    maintenance: "manutenzione",
    mortgage: "mutuo",
    taxi: "taxi",
    public_transport: "trasporto pubblico",
    travel: "viaggi",
    business_trips: "viaggi di lavoro",
    vehicle_insurance: "assicurazione veicoli",
    fuel: "carburante",
    vehicle_maintenance: "manutenzione veicoli",
    rentals: "noleggi",
    parking: "parcheggio",
    tolls: "pedaggi",
    alcohol: "alcol",
    tobacco: "tabacco",
    culture_sports_events: "cultura, eventi sportivi",
    donations_charity: "donazioni, carità",
    events: "eventi",
    wedding: "matrimonio",
    education_development: "formazione, sviluppo",
    hobbies: "hobby",
    books_audio_subscriptions: "libri, audio, abbonamenti",
    lotteries_gambling: "lotterie & gioco d'azzardo",
    health: "salute",
    sports_fitness: "sport, fitness",
    tv_streaming: "tv & streaming",
    holidays: "vacanze",
    hotels: "alberghi",
    internet: "internet",
    postal_services: "servizi postali",
    software_apps_games: "software, app & giochi",
    phone: "telefono",
    insurance: "assicurazioni",
    consulting: "consulenza",
    fines: "multe",
    loans_interest: "prestiti & interessi",
    taxes: "tasse",
    taxes_fees: "tasse, commissioni",
    real_estate: "beni immobili",
    movable_goods: "beni mobili",
    financial_investments: "investimenti finanziari",
    collections: "riscossioni",
    savings: "risparmi",
    checks: "assegni",
    coupons: "coupon",
    family_allowance: "assegno familiare",
    rental_income: "entrate da affitto",
    interest_dividends: "interessi, dividendi",
    loans_rentals: "prestiti, noleggi",
    gifts: "regali",
    refunds: "rimborsi (tasse, acquisti)",
    salary: "stipendio",
    sales: "vendite",
    transfer: "trasferimento",
    //payment methods
    cash: "contanti",
    credit_card: "carta di credito",
    debit_card: "carta di debito",
    transfer_back: "bonifico",
    check: "assegno",
    // currencies
    us_dollar: "dollaro statunitense",
    euro: "euro",
    british_pound: "sterlina britannica",
    japanese_yen: "yen giapponese",
    swiss_franc: "franco svizzero",
    canadian_dollar: "dollaro canadese",
    australian_dollar: "dollaro australiano",
    new_zealand_dollar: "dollaro neozelandese",
    swedish_krona: "corona svedese",
    norwegian_krone: "corona norvegese",
    danish_krone: "corona danese",
    singapore_dollar: "dollaro singaporeano",
    hong_kong_dollar: "dollaro hongkonghese",
    chinese_yuan: "yuan cinese",
    russian_ruble: "rublo russo",
    indian_rupee: "rupia indiana",
    indonesian_rupiah: "rupia indonesiana",
    brazilian_real: "real brasiliano",
    mexican_peso: "peso messicano",
    argentine_peso: "peso argentino",
    dues_grants: "quote, sovvenzioni",
    pastry_shop: "bar, pasticceria",
    pub: "pub",
    transportation: "trasporti",
    investments: "investimenti",
    food_beverages: "cibo & bevande",
    computer_communication: "comunicazione pc",
}
</script>
